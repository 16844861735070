import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { pageUrl } from "common/lib/router"

export const SearchForm = () => {
  const t = useTranslations("layout.header")
  const regionCode = useRegionCode()
  const searchPath = pageUrl.view({ regionCode, pageSlug: "search" })

  return (
    <form
      action={searchPath}
      acceptCharset="UTF-8"
      method="get"
      className="relative mx-2 my-4 flex basis-1/3 flex-row content-around"
    >
      <input
        type="text"
        name="q"
        className="w-full rounded-full border border-solid border-gray-300 py-3 pl-5 pr-10 text-xs text-black placeholder:text-gray-600 focus:placeholder:text-gray-200"
        placeholder={t("search_placeholder")}
      />

      <div className="absolute right-0 m-2">
        <button
          type="submit"
          value="Search"
          className="mt-px cursor-pointer border-none bg-transparent px-1"
        >
          <MagnifyingGlassIcon name="Search" role="search" className="size-6" />
        </button>
      </div>
    </form>
  )
}
