import type { RegionConfiguration } from "modules/i18n/utils/loadRegionConfiguration"
import { Locale } from "./locale"

export enum RegionCode {
  Au = "au",
  Africa = "africa",
  Brasil = "br",
  Ca = "ca",
  CaFr = "ca-fr",
  Es = "es",
  Europe = "europe",
  Fr = "fr",
  Global = "global",
  Id = "id",
  Nz = "nz",
  Uk = "uk",
  Us = "us",
}

export const DEFAULT_REGION_CODE = RegionCode.Au

export const REGION_COOKIE_NAME = "tc_region"

export type RegionToLocaleMap = {
  [key in RegionCode]: Locale
}

export const REGION_TO_LOCALE: RegionToLocaleMap = {
  [RegionCode.Au]: Locale.EnAu,
  [RegionCode.Africa]: Locale.EnZa,
  [RegionCode.Brasil]: Locale.PtBr,
  [RegionCode.Ca]: Locale.EnCa,
  [RegionCode.CaFr]: Locale.FrCa,
  [RegionCode.Es]: Locale.EsEs,
  [RegionCode.Europe]: Locale.EnEurope,
  [RegionCode.Fr]: Locale.FrFr,
  [RegionCode.Global]: Locale.EnGlobal,
  [RegionCode.Id]: Locale.IdId,
  [RegionCode.Nz]: Locale.EnNz,
  [RegionCode.Uk]: Locale.EnGb,
  [RegionCode.Us]: Locale.EnUs,
}

export const DEFAULT_REGION_CONFIG: RegionConfiguration = {
  footer: {
    first_column: [],
    second_column: [],
    social_media: [],
    copyright_first_column: [],
    copyright_notice_html: "The Conversation Media Group",
  },
  stay_informed: {
    topbar: {
      url: "",
    },
  },
  didomi: {
    enabled: false,
  },
  donations: {
    enabled: false,
  },
  section_nav: {
    custom_nav_link: {
      enabled: false,
    },
  },
  social_media: {
    facebook_url: "",
  },
}
