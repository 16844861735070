import clsx from "classnames"
import svgLogo from "common/assets/logo.svg"
import svgLogoCaFr from "common/assets/logo-cafr.svg"
import { Dropdown } from "../Dropdown"
import { Button } from "../Button"
import {
  HomeIcon,
  PencilSquareIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import { AccountMenu } from "../AccountMenu"
import { MobileMenu } from "../MobileMenu"
import { TcLogo } from "common/components/TcLogo"
import { useDropdown } from "common/hooks/interaction/useDropdown"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"
import { useViewer } from "common/hooks/data/useViewer"
import { usePageProps } from "common/hooks/data/usePageProps"
import { RegionCode } from "modules/i18n/config/region"
import type { AppProps } from "common/lib/getAppServerSideProps"

interface TopbarProps {
  wide?: boolean
}

export const Topbar = ({ wide }: TopbarProps) => {
  const regionCode = useRegionCode()
  const { editUrl, activeRegions } = usePageProps<AppProps>()
  const t = useTranslations("layout.header")
  const { stay_informed, donations } = useRegionConfig()
  const { viewer, isAuthor, isEditor } = useViewer()
  const user = viewer?.user
  const selectedRegion = activeRegions.find(({ code }) => code == regionCode)
  const remainingRegions = activeRegions.filter(({ code }) => code != regionCode)
  const regionedSvgLogo = regionCode === RegionCode.CaFr ? svgLogoCaFr : svgLogo
  const { ref, isActive, onClick } = useDropdown()

  const regionDropdownOverlay = (
    <>
      {remainingRegions.map(({ code, nameInRegionLanguage }) => (
        <Button href={`/${code}`} key={code} role="menuitem">
          {nameInRegionLanguage}
        </Button>
      ))}
    </>
  )
  return (
    <section
      className="relative z-20 border-b border-solid border-gray-200 bg-white"
      data-testid="Topbar"
    >
      <MobileMenu isActive={isActive} />

      <div className="absolute z-30 w-full lg:hidden" ref={ref}>
        <a
          href="#"
          className="absolute block p-2 text-gray-800"
          onClick={onClick}
          aria-label={isActive ? t("close") : t("menu")}
        >
          {isActive ? <XMarkIcon className="size-6" /> : <Bars3Icon className="size-6" />}
        </a>
      </div>

      <div className="tc-container relative mx-auto flex h-10 justify-between !p-0 text-black lg:h-12">
        <div className="flex grow flex-row flex-wrap items-center max-md:overflow-hidden">
          <a
            href={`/${regionCode}`}
            aria-label={t("go_to_homepage")}
            className="flex h-full text-gray-700 hover:!text-gray-700 max-md:grow lg:-ml-3"
          >
            <span className="hidden p-3 lg:block">
              <HomeIcon name="Home" role="button" className="size-6" />
            </span>

            <span className="flex size-full items-center">
              <img
                src={regionedSvgLogo}
                alt=""
                className="ml-10 mr-1 max-h-5 min-w-32 md:h-5 lg:hidden"
              />
            </span>
          </a>

          <div className="hidden h-full lg:block" data-testid="region-dropdown">
            <div className="relative h-full border-x border-solid border-gray-200">
              <div className="flex h-full items-center">
                <span className="ml-3 font-sans text-xs">{t("edition")}:</span>

                {selectedRegion && (
                  <Dropdown overlay={regionDropdownOverlay}>
                    <Button>
                      <div className="pr-2 font-bold text-red-600">
                        {selectedRegion.nameInRegionLanguage}
                      </div>
                      <ChevronDownIcon
                        name="Arrow pointing down"
                        className="size-3"
                        strokeWidth="2.5"
                      />
                    </Button>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>

          <div className="my-1 flex flex-row-reverse sm:flex-row md:mx-2">
            {donations.enabled && (
              <a
                href={`https://donate.theconversation.com/${regionCode}?utm_source=theconversation.com&utm_medium=website&utm_campaign=topbar`}
                className="m-1 hidden items-center rounded-full bg-red-600 px-3 py-1 text-xs font-bold text-white visited:text-white hover:bg-red-500 hover:text-white md:block lg:px-5 lg:py-2"
              >
                {t("donate")}
              </a>
            )}

            <a
              href={stay_informed.topbar.url}
              className="m-1 self-end rounded-full bg-black px-3 py-1 text-xs font-bold text-white visited:text-white hover:bg-gray-700 hover:text-white lg:px-5 lg:py-2"
            >
              {t("get_newsletter")}
            </a>
          </div>
        </div>

        <div className="flex w-auto flex-row items-center">
          <div className="hidden md:flex">
            {isEditor && editUrl && (
              <div className="flex h-full">
                <Button href={editUrl} withDivider>
                  <PencilSquareIcon className="w-5 pr-1" />
                  {t("user_navbar.edit_page")}
                </Button>
              </div>
            )}
            {isAuthor && user?.id && (
              <div className="flex h-full">
                <Button href={`/profiles/${user.id}/dashboard`} withDivider>
                  {t("user_navbar.dashboard")}
                </Button>
              </div>
            )}
            {isEditor && user?.id && (
              <div className="flex h-full">
                <Button href={`/editorial/dashboards/${user.id}`} withDivider>
                  {t("user_navbar.dashboard")}
                </Button>
              </div>
            )}
          </div>
          {!user && (
            <div className="flex h-full">
              <Button href="/become-an-author" withDivider>
                {t("user_navbar.become_an_author")}
              </Button>
              <Button href={`/sign_up?return_to=/${regionCode}`} withDivider>
                {t("user_navbar.signup_reader")}
              </Button>
            </div>
          )}
          <AccountMenu />
        </div>
      </div>
    </section>
  )
}
