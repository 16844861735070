import { AriaRole, PropsWithChildren } from "react"
import clsx from "classnames"
import Link from "next/link"

interface IButtonProps {
  href?: string
  onClick?: () => any
  type?: "alert" | "icon" | "basic" | "bold"
  withDivider?: boolean
  role?: AriaRole
  ariaLabel?: string
}

export const Button = ({
  href,
  children,
  type = "basic",
  withDivider,
  onClick,
  role,
  ariaLabel,
}: PropsWithChildren<IButtonProps>) => {
  const className = clsx("inline-flex items-center text-xs transition", {
    "border-r border-gray-200": withDivider,
    "bg-red-500 font-bold text-white hover:bg-red-700": type === "alert",
    "text-black hover:bg-gray-50": type !== "alert",
    "px-2.5": type === "icon",
    "py-3.5 px-2.5": type !== "icon",
    "font-bold": type === "bold",
  })

  if (href) {
    return (
      <a href={href} className={className} role={role} aria-label={ariaLabel}>
        {children}
      </a>
    )
  }
  return (
    <button onClick={onClick} className={className} role={role} aria-label={ariaLabel}>
      {children}
    </button>
  )
}
