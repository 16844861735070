import React from "react"
import { SearchForm } from "./SearchForm"
import { DesktopMenu } from "./DesktopMenu"
import { Topbar } from "./Topbar"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { TcLogo } from "common/components/TcLogo"

export interface HeaderProps {
  noDesktopMenu?: boolean
}

export const Header = ({ noDesktopMenu }: HeaderProps) => {
  const t = useTranslations("layout.header")

  return (
    <React.Fragment>
      <Topbar />
      <div className="hidden border-b border-solid border-gray-200 text-center lg:block">
        <div className="hidden font-sans lg:block">
          <section className="bg-masthead pt-5">
            <div className="tc-container container mx-auto">
              <header className="mx-auto flex justify-between">
                <div className="flex flex-col items-start">
                  <TcLogo width={350} height={40} />
                  <div className="mt-1 text-2xs font-bold text-gray-700">{t("slogan")}</div>
                </div>
                <SearchForm />
              </header>
              {noDesktopMenu ? null : <DesktopMenu />}
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}
